import {streamCluster} from "~/stores/serverClusters";
import {useEnvironment} from "~/stores/spaces";
import {useNamespace} from "~/stores/namespaces";


const defaultProperties = `
# Default Axon Server configuration that you might want to change
# axoniq.axonserver.name=axonserver-1          # Display name in console - needs to be unique
# axoniq.axonserver.hostname=axonserver-1      # Hostname or IP address to bind to

# server.port=8124                             # Port for the Dashboard on HTTP
# axoniq.axonserver.port=8124                  # GRPC Port for Framework clients
# axoniq.axonserver.internal-port=8224         # GRPC Port for Axon Server internal communication
`

export const useDemoHelpersForCurrent = () => {
    const {workspaceId, environmentId, clusterId} = useEnvironment()
    return useDemoHelpers(workspaceId.value, environmentId.value, clusterId.value)
}

export const useDemoHelpers = (workspaceId: string, environmentId: string, clusterId: string) => {
    const environmentConfig = getConfig();
    const prodConfig = getProdConfig();
    const {cluster} = streamCluster(workspaceId, environmentId, clusterId)
    const {namespace} = useNamespace(workspaceId, environmentId)

    const accessTokenForServer = computed(() => {
        return cluster.value?.accessTokens[0]?.accessToken ?? ''
    })
    const credentialsForServer = computed(() => {
        return `${cluster.value?.clusterId}:${accessTokenForServer.value}`
    })

    const accessTokenForFramework = computed(() => {
        return namespace.value?.accessTokens[0]?.token ?? ''
    })
    const credentialsForFramework = computed(() => {
        return `${namespace.value.identifier}:${accessTokenForFramework.value}`
    })


    const serverProperties = computed(() => {
        let total = `# AxonIQ Console connection\naxoniq.console.authentication=${credentialsForServer.value}`;
        if (environmentConfig.controlTowerHost !== prodConfig.controlTowerHost) {
            total += `\naxoniq.console.host=${environmentConfig.controlTowerHost}`
        }
        if (environmentConfig.controlTowerPort !== prodConfig.controlTowerPort) {
            total += `\naxoniq.console.port=${environmentConfig.controlTowerPort}`
        }
        if (environmentConfig.controlTowerUnsecure) {
            total += `\naxoniq.console.secure=false`
        }
        return total + '\n' + defaultProperties
    })

    const serverEnvironmentForDocker = computed(() => {
        let total = `\n      - axoniq_console_authentication=${credentialsForServer.value}`;
        if (environmentConfig.controlTowerHost !== prodConfig.controlTowerHost) {
            total += `\n      - axoniq_console_host=${environmentConfig.controlTowerHost}`
        }
        if (environmentConfig.controlTowerPort !== prodConfig.controlTowerPort) {
            total += `\n      - axoniq_console_port=${environmentConfig.controlTowerPort}`
        }
        if (environmentConfig.controlTowerUnsecure) {
            total += `\n      - axoniq_console_secure=false`
        }
        return total
    })

    const frameworkEnvironmentForDocker = computed(() => {
        let total = `\n      - axoniq_console_credentials=${credentialsForFramework.value}`;
        if (environmentConfig.connectorHost !== prodConfig.connectorHost) {
            total += `\n      - axoniq_console_host=${environmentConfig.connectorHost}`
        }
        if (environmentConfig.connectorPort !== prodConfig.connectorPort) {
            total += `\n      - axoniq_console_port=${environmentConfig.connectorPort}`
        }
        if (environmentConfig.connectorUnsecure) {
            total += `\n      - axoniq_console_secure=false`
        }
        return total
    })

    return {
        accessTokenForServer,
        credentialsForServer,
        serverProperties,
        serverEnvironmentForDocker,
        frameworkEnvironmentForDocker,
    }
}